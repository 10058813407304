import * as React from 'react';
import { Card } from '../Card/Card';
import { useAppContext } from '../../contexts/AppContext';
import styled from '@emotion/styled';
import { setDefaultStyleValues } from '../../utils/set-default-style-values';
import { useTheme } from '@mui/material';
import { BANNER_RIGHT_CARD_BUTTON_STYLE } from '../../types/static';
import { Link } from '../Link';
import { Title } from '../Title';

const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& .MuiCard-root': {
    height: '300px',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  '& .MuiCardContent-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    width: '300px',
    minWidth: '300px',
    '& .card-actions': {
      marginTop: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiCard-root': {
      height: '450px',
      flexDirection: 'column',
    },
    '& .MuiCardContent-root': {
      width: '100%',
      minWidth: 'unset',
      flex: '1 1 auto',
    },
  },
}));

export const BannerHorizontalA = (props: any) => {
  const theme = useTheme();
  const { items, component, template, slug, name } = props;
  const appContext = useAppContext();
  const { assetUrl } = appContext.useConfig();

  return (
    <>
      {!props?.component?.hideTitle && (
        <Link href={`/${props.type}/${props.slug}`} title={props.name || undefined} underline="none">
          <Title variant={'h2'}>{props.name}</Title>
        </Link>
      )}
      {items?.slice(0, 1).map((item: any, index: number) => (
        <Container key={`banner-${item.id || index}`}>
          <Card
            href={`/game/${item.slug}`}
            src={`${assetUrl}${item.images?.cover16x9 || item.images?.cover}/`}
            alt={item.name}
            title={item.name}
            description={item.description}
            template={template}
            width={1200}
            height={330}
            hideDescription={component?.hideDescription}
            innerFlex="row-reverse"
            alignItems="stretch"
            collection={{ name, slug }}
            assetsx={{
              flex: '1 1 auto',
              height: '100%',
              objectFit: 'cover',
              [theme.breakpoints.down('sm')]: {
                flex: '1 1 50%',
              },
            }}
            configurationStyle={setDefaultStyleValues(BANNER_RIGHT_CARD_BUTTON_STYLE, theme)}
          />
        </Container>
      ))}
    </>
  );
};
