export const DEVELOPMENT_USER_AVATAR_LIST = [
  'e7f150de-693c-40f8-b99f-6f391497924f',
  '54df2df6-6893-44ff-bd59-dc5f069d4966',
  '0cda05de-d9c7-4559-972a-2b254eb5edeb',
  'e3853542-29d3-4067-ab73-6f43e42ba0f6',
  '968ff027-b6e6-4be7-92fc-0028916d76e8',
  '072fadf5-62ff-4e8a-86ae-f3d4d5cb5122',
];

export const PRODUCTION_USER_AVATAR_LIST = [
  'f7ffed9a-12d6-4483-94d1-4d9976d69529',
  '3d89d0c3-72c7-4e8e-91d2-91be99c5f642',
  'ba16bc1b-45fd-4af5-a534-05138ab48735',
  'e96a3df4-1589-45cc-b440-f1320faef777',
  '2ff729ff-9341-4803-bb15-595a69bcab0a',
  '552bcaf1-7302-4771-a2ac-97971f665842',
];

export const LAYOUTADSCONFIGRATION_AC = [
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_tablet_ads_disabled',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_ads_mobile_disabled',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const LAYOUTADSCONFIGRATION_AC_BOTTOM = [
  {
    name: 'component_layout_right_middle_ads_disabled',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_middle_tablet_ads_disabled',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_middle_mobile_ads_disabled',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const LAYOUTADSCONFIGRATION_B = [
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_ads_tablet_disabled',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_ads_mobile_disabled',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const COLLECTIONS_B_TOP = [
  {
    name: 'component_right_ads_disable',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_right_ads_tablet_disable',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_right_ads_mobile_disable',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const COLLECTIONS_B_MIDDLE = [
  {
    name: 'component_middle_center_ads_disable',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_middle_center_ads_tablet_disable',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_middle_center_ads_mobile_disable',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const COLLECTIONS_B_BOTTOM = [
  {
    name: 'component_bottom_ads_disable',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_bottom_ads_tablet_disable',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_bottom_ads_mobile_disable',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_GAME_LEFT_ADS_CONFIGURATION = [
  {
    name: 'aggressive_game_left_id',
    label: 'Left ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_left_ads_disabled',
    label: 'Disable left ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_GAME_LEFT_MIDDLE_ADS_CONFIGURATION = [
  {
    name: 'aggressive_game_left_middle_id',
    label: 'Left middle ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_left_middle_ads_disabled',
    label: 'Disable left middle ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_GAME_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'aggressive_game_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Disable right ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_GAME_RIGHT_MIDDLE_ADS_CONFIGURATION = [
  {
    name: 'aggressive_game_right_middle_id',
    label: 'Right middle ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_right_middle_ads_disabled',
    label: 'Disable right middle ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_GAME_TOP_ADS_CONFIGURATION = [
  {
    name: 'aggressive_game_atf_id',
    label: 'Above the fold ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_top_ads_disabled',
    label: 'Disable top ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_COLLECTION_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'aggressive_collection_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const AGGRESSIVE_COLLECTION_RIGHT_MIDDLE_ADS_CONFIGURATION = [
  {
    name: 'aggressive_collection_right_middle_id',
    label: 'Right middle ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const AGGRESSIVE_SEARCH_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'aggressive_search_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const AGGRESSIVE_SEARCH_RIGHT_MIDDLE_ADS_CONFIGURATION = [
  {
    name: 'aggressive_search_right_middle_id',
    label: 'Right middle ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const MODERATE_GAME_LEFT_ADS_CONFIGURATION = [
  {
    name: 'moderate_game_left_id',
    label: 'Left ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_left_ads_disabled',
    label: 'Disable left ads',
    type: 'checkbox',
    default: false,
  },
];

export const MODERATE_GAME_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'moderate_game_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Disable right ads',
    type: 'checkbox',
    default: false,
  },
];

export const MODERATE_GAME_TOP_ADS_CONFIGURATION = [
  {
    name: 'moderate_game_atf_id',
    label: 'Above the fold ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_top_ads_disabled',
    label: 'Disable top ads',
    type: 'checkbox',
    default: false,
  },
];

export const CONSERVATIVE_GAME_LEFT_ADS_CONFIGURATION = [
  {
    name: 'conservative_game_left_id',
    label: 'Left ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_left_ads_disabled',
    label: 'Disable left ads',
    type: 'checkbox',
    default: false,
  },
];

export const CONSERVATIVE_GAME_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'conservative_game_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Disable right ads',
    type: 'checkbox',
    default: false,
  },
];
export const CONSERVATIVE_HOME_TOP_ADS_CONFIGURATION = [
  {
    name: 'conservative_home_atf_id',
    label: 'Above the fold ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_top_ads_disabled',
    label: 'Disable top ads',
    type: 'checkbox',
    default: false,
  },
];

export const CONSERVATIVE_HOME_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'conservative_home_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Disable right ads',
    type: 'checkbox',
    default: false,
  },
];

export const CONSERVATIVE_COLLECTION_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'conservative_collection_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const CONSERVATIVE_SEARCH_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'conservative_search_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const MODERATE_HOME_LEFT_ADS_CONFIGURATION = [
  {
    name: 'moderate_home_left_id',
    label: 'Left ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_left_ads_disabled',
    label: 'Disable left ads',
    type: 'checkbox',
    default: false,
  },
];

export const MODERATE_HOME_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'moderate_home_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Disable right ads',
    type: 'checkbox',
    default: false,
  },
];

export const MODERATE_HOME_TOP_ADS_CONFIGURATION = [
  {
    name: 'moderate_home_atf_id',
    label: 'Above the fold ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_top_ads_disabled',
    label: 'Disable top ads',
    type: 'checkbox',
    default: false,
  },
];

export const MODERATE_HOME_BOTTOM_ADS_CONFIGURATION = [
  {
    name: 'moderate_home_bottom_id',
    label: 'Bottom ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_bottom_ads_disable',
    label: 'Disable bottom ads',
    type: 'checkbox',
    default: false,
  },
];

export const MODERATE_COLLECTION_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'moderate_collection_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const MODERATE_SEARCH_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'moderate_search_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const AGGRESSIVE_HOME_LEFT_ADS_CONFIGURATION = [
  {
    name: 'aggressive_home_left_id',
    label: 'Left ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_left_ads_disabled',
    label: 'Disable left ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_HOME_LEFT_MIDDLE_ADS_CONFIGURATION = [
  {
    name: 'aggressive_home_left_middle_id',
    label: 'Left middle ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_left_middle_ads_disabled',
    label: 'Disable left middle ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_HOME_RIGHT_ADS_CONFIGURATION = [
  {
    name: 'aggressive_home_right_id',
    label: 'Right ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Disable right ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_HOME_RIGHT_MIDDLE_ADS_CONFIGURATION = [
  {
    name: 'aggressive_home_right_middle_id',
    label: 'Right middle ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_layout_right_middle_ads_disabled',
    label: 'Disable right middle ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_HOME_TOP_ADS_CONFIGURATION = [
  {
    name: 'aggressive_home_atf_id',
    label: 'Above the fold ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_top_ads_disabled',
    label: 'Disable top ads',
    type: 'checkbox',
    default: false,
  },
];

export const AGGRESSIVE_HOME_BOTTOM_ADS_CONFIGURATION = [
  {
    name: 'aggressive_home_bottom_id',
    label: 'Bottom ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_bottom_ads_disable',
    label: 'Disable bottom ads',
    type: 'checkbox',
    default: false,
  },
];

export const HOME_MIDDLE_ADS_CONFIGURATION = [
  {
    name: 'home_middle_id',
    label: 'Middle ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const COLLECTION_MIDDLE_ADS_CONFIGURATION = [
  {
    name: 'collection_middle_id',
    label: 'Middle ad slot identifier',
    type: 'text',
    default: '',
  },
  {
    name: 'component_collection_ads_middle_disabled',
    label: 'Disable middle ads',
    type: 'checkbox',
    default: false,
  },
];

export const SEARCH_MIDDLE_ADS_CONFIGURATION = [
  {
    name: 'search_middle_id',
    label: 'Middle ad slot identifier',
    type: 'text',
    default: '',
  },
];

export const FEATURE_GRID_A_CARD_BUTTON_STYLE = [
  {
    name: 'theme_component_feature_grid_a_card_button_border_radius',
    label: 'Button border radius',
    type: 'number',
    endAdornment: 'px',
    default: 16,
  },
  {
    name: 'theme_component_feature_grid_a_card_button_font_size',
    label: 'Button font size',
    type: 'number',
    endAdornment: 'px',
    default: 16,
  },
  {
    name: 'theme_component_feature_grid_a_card_button_font_weight',
    label: 'Button font weight',
    type: 'number',
    default: 400,
  },
  {
    name: 'theme_component_feature_grid_a_card_button_color',
    label: 'Button color',
    type: 'color',
    default: '#000',
  },
  {
    name: 'theme_component_feature_grid_a_card_button_background',
    label: 'Button background',
    type: 'color',
    default: '#fff',
  },
  {
    name: 'theme_component_feature_grid_a_card_button_hover_background_color',
    label: 'Button hover background',
    type: 'color',
    default: '#000',
  },
  {
    name: 'theme_component_feature_grid_a_card_button_active_background_color',
    label: 'Button active background',
    type: 'color',
    default: '#000',
  },
];

export const CAROUSEL_A_CARD_BUTTON_STYLE = [
  {
    name: 'theme_component_carousel_a_card_button_border_radius',
    label: 'Button border radius',
    type: 'number',
    endAdornment: 'px',
    default: 16,
  },
  {
    name: 'theme_component_carousel_a_card_button_font_size',
    label: 'Button font size',
    type: 'number',
    endAdornment: 'px',
    default: 16,
  },
  {
    name: 'theme_component_carousel_a_card_button_font_weight',
    label: 'Button font weight',
    type: 'number',
    default: 400,
  },
  {
    name: 'theme_component_carousel_a_card_button_color',
    label: 'Button color',
    type: 'color',
    default: '#000',
  },
  {
    name: 'theme_component_carousel_a_card_button_background',
    label: 'Button background',
    type: 'color',
    default: '#fff',
  },
  {
    name: 'theme_component_carousel_a_card_button_hover_background_color',
    label: 'Button hover background',
    type: 'color',
    default: '#000',
  },
  {
    name: 'theme_component_carousel_a_card_button_active_background_color',
    label: 'Button active background',
    type: 'color',
    default: '#000',
  },
];

export const BANNER_RIGHT_CARD_BUTTON_STYLE = [
  {
    name: 'theme_component_banner_right_card_button_border_radius',
    label: 'Button border radius',
    type: 'number',
    endAdornment: 'px',
    default: 16,
  },
  {
    name: 'theme_component_banner_right_card_button_font_size',
    label: 'Button font size',
    type: 'number',
    endAdornment: 'px',
    default: 16,
  },
  {
    name: 'theme_component_banner_right_card_button_font_weight',
    label: 'Button font weight',
    type: 'number',
    default: 400,
  },
  {
    name: 'theme_component_banner_right_card_button_color',
    label: 'Button color',
    type: 'color',
    default: '#000',
  },
  {
    name: 'theme_component_banner_right_card_button_background',
    label: 'Button background',
    type: 'color',
    default: '#fff',
  },
  {
    name: 'theme_component_banner_right_card_button_hover_background_color',
    label: 'Button hover background',
    type: 'color',
    default: '#000',
  },
  {
    name: 'theme_component_banner_right_card_button_active_background_color',
    label: 'Button active background',
    type: 'color',
    default: '#000',
  },
];
