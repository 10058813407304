import type { LayoutProps } from '../../types';

import * as React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import { useTheme } from '@mui/material/styles';
import {
  AGGRESSIVE_COLLECTION_RIGHT_ADS_CONFIGURATION,
  AGGRESSIVE_COLLECTION_RIGHT_MIDDLE_ADS_CONFIGURATION,
} from '../../types/static';

const AdvertisementSlot = dynamic<any>(
  () => import('../../components/AdvertisementSlot').then((mod) => mod.AdvertisementSlot),
  {
    ssr: false,
  }
);

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: 'auto',
  justifyContent: 'space-between',
}));

const Sides = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up(1220)]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexWrap: 'nowrap',
    height: 'fit-content',
    justifyContent: 'center',
    padding: theme.spacing(7, 2, 2),
    top: 50,
    position: 'sticky',
  },
}));

const SectionMiddle = styled('div')<{ isAdsRightVisible: boolean }>(({ theme, isAdsRightVisible }) => ({
  marginTop: theme.spacing(7),
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'start',
  maxWidth: isAdsRightVisible ? '1920px' : '1920px',
  width: '100%',
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down(900)]: {
    maxWidth: '100%',
    padding: theme.spacing(0, 1),
  },
}));

const AdvertisementSlotStyled = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  margin: theme.spacing(0, 0, 4),
  backgroundColor: 'grey',
  width: 300,
}));

const AdsRight = ({ slotIdentifier, variant }: { slotIdentifier: string; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={AGGRESSIVE_COLLECTION_RIGHT_ADS_CONFIGURATION}
      slotIdentifier={slotIdentifier}
      elevation={1}
      variant={variant}
      alignment="left"
    />
  );
};

const AdsRightMiddle = ({ slotIdentifier, variant }: { slotIdentifier: string; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={AGGRESSIVE_COLLECTION_RIGHT_MIDDLE_ADS_CONFIGURATION}
      slotIdentifier={slotIdentifier}
      variant={variant}
      alignment="left"
      elevation={1}
    />
  );
};

export const AggressiveCollection = (props: LayoutProps) => {
  const { children } = props;
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  const isAdsRightVisible =
    !componentOptions.component_layout_right_ads_disabled && lg;

  return (
    <Root>
      <div></div>
      <SectionMiddle isAdsRightVisible={isAdsRightVisible}>{children}</SectionMiddle>
      {isAdsRightVisible && (
        <Sides>
          <AdsRight slotIdentifier={componentOptions.aggressive_collection_right_id || ''} variant="300x600" />
          {!componentOptions.component_layout_right_middle_ads_disabled && (
            <AdsRightMiddle
              slotIdentifier={componentOptions.aggressive_collection_right_middle_id || ''}
              variant="300x170"
            />
          )}
        </Sides>
      )}
    </Root>
  );
};
