import React, { useState } from 'react';
import { Card } from '../Card';
import { useAppContext } from '../../contexts/AppContext';
import styled from '@emotion/styled';
import { Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { FEATURE_GRID_A_CARD_BUTTON_STYLE } from '../../types/static';
import { setDefaultStyleValues } from '../../utils/set-default-style-values';
import { Card as MuiCard } from '@mui/material';
import { CustomButton } from '../CustomButton';
import { Link } from '../Link';
import { Title } from '../Title';
import { isValidUrl } from '../../utils/is-valid-url';

export interface CollectionCommonCProps {
  template: string;
  items: any;
  component?: any;
  type?: any;
  slug?: any;
  name?: any;
}

const ProductSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridTemplateRows: 'repeat(4, 1fr)',
  gap: theme.spacing(1.5, 2.5),
  padding: 0,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'auto',
  },
}));

const CardStyled = styled(Card)(({ theme, template, hoverEffect, cardBorder }: any) => ({
  width: '100%',
  backgroundColor: theme.theme_component_card_background_color || theme.palette.background.paper,
  boxShadow: theme.theme_component_card_box_shadow || theme.shadows[3],
  borderRadius: template === 'big' ? 0 : `${theme.theme_component_card_border_radius}px` || theme.shape.borderRadius,
  border: cardBorder || `${theme.theme_component_card_content_border}px` || 'none',
  borderStyle: theme.theme_component_card_content_border ? 'solid' : 'none',
  borderColor: theme.theme_component_card_content_border ? theme.theme_component_card_content_border_color : 'none',
  transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
  aspectRatio: 1,
  '&:hover': hoverEffect && {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
}));

const ProductItem = styled(CardStyled)(({ theme }) => ({
  gridColumn: '2 / span 3',
  gridRow: '1 / span 3',
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / span 1',
    gridRow: '1 / span 1',
    '& .inner-card': {
      '& > div:last-of-type': {
        display: 'none',
      },
    },
  },
}));

export const FeatureGridB = (props: CollectionCommonCProps) => {
  const theme = useTheme();
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { items = [], template, component, slug, name } = props;
  const [showAll, setShowAll] = useState(false);
  const appContext = useAppContext();
  const { assetUrl } = appContext.useConfig();
  const validUrl = isValidUrl(assetUrl?.replace(/\/+$/, ''));
  const baseImageUrl = validUrl ? '' : assetUrl;
  const [currentMainIndex, setCurrentMainIndex] = useState(0);

  React.useEffect(() => {
    if (isSmDown) {
      // SM down olduğunda currentMainIndex güncellenmesin
      return;
    }

    const intervalId = setInterval(() => {
      setCurrentMainIndex((prevIndex: number) => (prevIndex + 1) % items.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [items.length, isSmDown]);

  // Breakpoints altında öğeleri filtrele
  let filteredItems = items;
  if (isSmDown) {
    filteredItems = items.slice(0, 2);
  } else if (isLgDown) {
    filteredItems = items.slice(0, 8);
  }

  const getImageUrl = (game: any, main: boolean) => {
    return `${baseImageUrl}${
      main
        ? game.images['512x384'] || game.images['512x512'] || game.images.cover
        : game.images['512x512'] || game.images['512x384'] || game.images.cover
    }/`;
  };

  return (
    <Box mb={5}>
      {!props?.component?.hideTitle && (
        <Link href={`/${props.type}/${slug}`} title={name || undefined} underline="none">
          <Title variant={'h2'}>{name}</Title>
        </Link>
      )}
      <ProductSection>
        {filteredItems.map((game: any, index: number) => {
          if (index === currentMainIndex) {
            return (
              <ProductItem
                key={`${game.id}_${index}`}
                href={`/game/${game.slug}`}
                collection={{ name, slug }}
                src={getImageUrl(game, true)}
                description={game.description || ''}
                alt={game.name}
                title={game.name || undefined}
                sx={{ display: 'flex' }}
                template={template}
                width={700}
                height={550}
                layout="responsive"
                hideDescription={component?.hideDescription}
                configurationStyle={setDefaultStyleValues(FEATURE_GRID_A_CARD_BUTTON_STYLE, theme)}
              />
            );
          } else {
            return (
              <CardStyled
                key={`${game.id}_${index}`}
                href={`/game/${game.slug}`}
                collection={{ name, slug }}
                src={getImageUrl(game, false)}
                alt={game.name}
                sx={{ display: 'flex' }}
                template={template}
                width={335}
                height={315}
                layout="responsive"
                hideDescription={component?.hideDescription}
                configurationStyle={setDefaultStyleValues(FEATURE_GRID_A_CARD_BUTTON_STYLE, theme)}
              />
            );
          }
        })}
      </ProductSection>
    </Box>
  );
};
