import type { LayoutProps } from '../../types';

import * as React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import { useTheme } from '@mui/material/styles';
import { AggressiveTopAds } from './AggressiveGameTopAds';
import {
  AGGRESSIVE_GAME_LEFT_ADS_CONFIGURATION,
  AGGRESSIVE_GAME_LEFT_MIDDLE_ADS_CONFIGURATION,
  AGGRESSIVE_GAME_RIGHT_ADS_CONFIGURATION,
  AGGRESSIVE_GAME_RIGHT_MIDDLE_ADS_CONFIGURATION,
} from '../../types/static';

const AdvertisementSlot = dynamic<any>(
  () => import('../../components/AdvertisementSlot').then((mod) => mod.AdvertisementSlot),
  {
    ssr: false,
  }
);

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: 'auto',
  justifyContent: 'space-between',
}));

const Sides = styled('div')<{ shouldHideOnSmallScreens?: boolean }>(({ theme, shouldHideOnSmallScreens }) => ({
  display: 'none',
  [theme.breakpoints.up("lg")]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexWrap: 'nowrap',
    height: 'fit-content',
    justifyContent: 'center',
    padding: theme.spacing(7, 2, 2),
    top: 50,
    position: 'sticky',
  },
  ...(shouldHideOnSmallScreens && {
    [theme.breakpoints.down(1500)]: {
      display: 'none',
    },
  }),
}));

const SectionMiddle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexWrap: 'nowrap',
  height: 'auto',
  justifyContent: 'center',
  maxWidth: 1920,
  flex: 1,
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down(900)]: {
    maxWidth: '100%',
    padding: theme.spacing(0, 1),
  },
}));

const AdvertisementSlotStyled = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  margin: theme.spacing(0, 0, 4),
  backgroundColor: 'grey',
  width: 300,
}));

const AdsLeft = ({ slotIdentifier, variant }: { slotIdentifier: string; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={AGGRESSIVE_GAME_LEFT_ADS_CONFIGURATION}
      slotIdentifier={slotIdentifier}
      elevation={1}
      variant={variant}
      alignment="right"
    />
  );
};

const AdsLeftMiddle = ({ slotIdentifier, variant }: { slotIdentifier: string; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={AGGRESSIVE_GAME_LEFT_MIDDLE_ADS_CONFIGURATION}
      slotIdentifier={slotIdentifier}
      variant={variant}
      alignment="right"
      elevation={1}
    />
  );
};

const AdsRight = ({ slotIdentifier, variant }: { slotIdentifier: string; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={AGGRESSIVE_GAME_RIGHT_ADS_CONFIGURATION}
      slotIdentifier={slotIdentifier}
      elevation={1}
      variant={variant}
      alignment="left"
    />
  );
};

const AdsRightMiddle = ({ slotIdentifier, variant }: { slotIdentifier: string; variant: string }) => {
  return (
    <AdvertisementSlotStyled
      configuration={AGGRESSIVE_GAME_RIGHT_MIDDLE_ADS_CONFIGURATION}
      slotIdentifier={slotIdentifier}
      variant={variant}
      alignment="left"
      elevation={1}
    />
  );
};

export const AggressiveGame = (props: LayoutProps) => {
  const { children } = props;
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Root>
      {!componentOptions.component_layout_left_ads_disabled && lg && (
        <Sides shouldHideOnSmallScreens={true}>
          <AdsLeft slotIdentifier={componentOptions.aggressive_game_left_id || ''} variant="300x600" />
          {!componentOptions.component_layout_left_middle_ads_disabled && (
            <AdsLeftMiddle slotIdentifier={componentOptions.aggressive_game_left_middle_id || ''} variant="300x250" />
          )}
        </Sides>
      )}
      <SectionMiddle>
        {(!componentOptions.component_top_ads_disabled && !downMd) && <AggressiveTopAds />}
        {children}
      </SectionMiddle>
      {!componentOptions.component_layout_right_ads_disabled && lg && (
        <Sides>
          <AdsRight slotIdentifier={componentOptions.aggressive_game_right_id || ''} variant="300x600" />
          {!componentOptions.component_layout_right_middle_ads_disabled && (
            <AdsRightMiddle slotIdentifier={componentOptions.aggressive_game_right_middle_id || ''} variant="300x250" />
          )}
        </Sides>
      )}
    </Root>
  );
};
