import * as React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '../Link';
import { DrawerEnum } from '../../types/enum';
import Image, { ImageLoaderProps } from 'next/image';
import useDrawer from '../../utils/useDrawer';
import { ToolbarRightContainer } from '../NavigationbarCommon/toolbarRightContainer/ToolbarRightContainer';
import { DrawerWrapper } from '../NavigationbarCommon/drawer';
import { WithNavigationbar } from '../NavigationBar/NavigationBar';
import FacebookIcon from './icons/facebook';
import YoutubeIcon from './icons/youtube';
import TwitterIcon from './icons/twitter';
import InstagramIcon from './icons/instagram';
import TiktokIcon from './icons/tiktok';
import TwitchIcon from './icons/twitch';
import LinkedInIcon from './icons/linkedin';
import SbtGamesIcon from './icons/sbtgames';
import { SocialMediaLinksProps } from '../../types';
import useResizeObserver from '../../utils/useResizeObserver';

const NavigationMenu = dynamic<any>(() => import('../Navigation/NavigationMenu').then((mod) => mod.NavigationMenu), {
  ssr: false,
});

const SocialMediaWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(1),
  },
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.theme_component_navigation_bar_background_color || theme.palette.primary.main,
  borderBottom: `${theme.theme_component_navigation_bar_border_bottom_width || 0}px solid ${
    theme.theme_component_navigation_bar_border_bottom_color || theme.palette.divider
  }`,
}));

const TopHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(4, 2),
  height: '40px',
  background: theme.palette.background.default || theme.theme_component_navigation_bar_background_color,
}));

const BottomHeader = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 2),
  position: 'relative',
}));

const LogoSection = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchSection = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const ButtonLogo = styled(Button)(({ theme }) => ({
  width: theme.theme_component_navigation_bar_logo_width || '120px',
  height: '60px',
  padding: 0,
})) as typeof Button;

const DrawerIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
})) as typeof IconButton;

const DrawerIcon = styled(MenuIcon)(({ theme }) => ({
  color: theme.theme_component_navigation_bar_icon_color || theme.palette.primary.contrastText,
}));

const NavigationMenuContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  height: '100%',
  width: 'auto',
}));

const MyAuto = styled('div')(() => ({}));

interface SocialMediaIconProps {
  platform: string;
  theme: any;
  className?: string;
  href?: string;
}

const SocialMediaIcon = styled(({ className, href="", ...props }: SocialMediaIconProps) => {
  const theme = useTheme() as any;
  const IconComponent = {
    linkedin: LinkedInIcon,
    twitch: TwitchIcon,
    youtube: YoutubeIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
    sbt: SbtGamesIcon,
    facebook: FacebookIcon,
    tiktok: TiktokIcon,
  }[props.platform.toLowerCase()];
  console.log('IconComponent', IconComponent);
  return IconComponent ? (
    <Link href={href} target="_blank" rel="noreferrer">
      <IconComponent
        className={className}
        style={{}}
        color={theme.theme_component_navigation_bar_background_color || theme.palette.primary.main}
      />
    </Link>
  ) : null;
})(({ theme }) => ({
  marginRight: '15px',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const NavigationBarDouble = () => {
  const theme = useTheme();
  const appContext = useAppContext();
  const { url, componentOptions, assetUrl, headerLogoId } = appContext.useConfig();

  const drawerSearchAnchor: DrawerEnum =
    componentOptions.component_navigation_bar_search_drawer_anchor || DrawerEnum.RIGHT;
  const drawerAnchor: DrawerEnum = componentOptions.component_navigation_bar_drawer_anchor || DrawerEnum.LEFT;
  const drawerDisabled = componentOptions.component_navigation_bar_drawer_disabled || false;

  const socialMediaLinks = componentOptions?.component_social_media_links || [];

  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  const { drawer, setDrawer, drawerSearch, setDrawerSearch, handleToggle, handleSearchToggle, handleClose } = useDrawer(
    drawerAnchor,
    drawerSearchAnchor
  );

  const [menuSliceAmount, setMenuSliceAmount] = React.useState<number>(7);

  const toolBarLeftRef = React.useRef<HTMLDivElement>(null);
  const toolBarCenterRef = React.useRef<HTMLDivElement>(null);
  const toolBarRightRef = React.useRef<HTMLDivElement>(null);

  const refs = [toolBarLeftRef, toolBarCenterRef, toolBarRightRef];
  useResizeObserver(refs, setMenuSliceAmount, theme);

  return (
    <HeaderContainer>
      <TopHeader>
        {md && (
          <SocialMediaWrapper ref={toolBarLeftRef}>
            <MyAuto className="my-auto">
              {socialMediaLinks?.map((link: SocialMediaLinksProps) => (
                <SocialMediaIcon
                  className=""
                  key={link.platform}
                  platform={link.platform}
                  href={link.href}
                  theme={theme}
                />
              ))}
            </MyAuto>
          </SocialMediaWrapper>
        )}

        <LogoSection ref={toolBarCenterRef}>
          <ButtonLogo component={Link} href="/" title={url} aria-label="Home">
            <Image
              loader={({ src }: ImageLoaderProps) => src}
              src={`${assetUrl}${headerLogoId}/`}
              unoptimized
              alt={url || ''}
              layout="fill"
              objectFit="contain"
            />
          </ButtonLogo>
        </LogoSection>
        <SearchSection ref={toolBarRightRef}>
          <ToolbarRightContainer
            setDrawerSearch={setDrawerSearch}
            handleClose={handleClose}
            drawerSearch={drawerSearch}
            drawerSearchAnchor={drawerSearchAnchor}
          />
        </SearchSection>
      </TopHeader>
      <Container maxWidth={false} sx={{ padding: 0 }}>
        <BottomHeader className="asd12123asdads">
          {!componentOptions.component_header_menu_hidden && (
            <DrawerIconButton
              edge="start"
              size={sm ? 'medium' : 'small'}
              onClick={() => setDrawer({ ...drawer, [drawerAnchor]: true })}
              aria-label="menu"
            >
              <DrawerIcon />
            </DrawerIconButton>
          )}
          {lg && (
            <NavigationMenuContainer>
              <NavigationMenu sliceAmount={menuSliceAmount} />
            </NavigationMenuContainer>
          )}
        </BottomHeader>
      </Container>

      {!drawerDisabled && (
        <DrawerWrapper
          drawer={drawer}
          handleToggle={handleToggle}
          drawerSearch={drawerSearch}
          handleSearchToggle={handleSearchToggle}
        >
          <NavigationMenu />
        </DrawerWrapper>
      )}
    </HeaderContainer>
  );
};

const NavigationDouble = WithNavigationbar(NavigationBarDouble);

export { NavigationDouble };
