import type { LayoutProps } from '../../types';

import * as React from 'react';
import { AggressiveCollection as LayoutComponent } from '../../layouts/AggressiveCollection';

export function Layout(props: LayoutProps) {
  const { children, pageContext } = props;
  return (
    <LayoutComponent pageContext={pageContext}>
      {children}
    </LayoutComponent>
  );
}
