import type { CategoryRequestProps, GameRequestProps, IUserProps, MediasRequestProps, Pegi } from '../types';

import axios from 'axios';
import qs from 'qs';
import { CollectionTypesEnum } from '../types/enum';
import { IKidForm } from '../types/dto';
interface ApiProps {
  ssr?: boolean;
  locale?: string | undefined;
}

interface ApiQueryString {
  period?: 'day' | 'week' | 'month' | 'quarter' | 'half' | 'year' | 'all';
  _limit?: number;
  _fieldsGame?: string;
  _gameLimit?: number;
  _gameRandom?: boolean;
}

/**
 * Todo: HUZ2ERA-1100 - This file should be optimized/ refactored:
 *       * Lots of duplicate code.
 *       * Unit tests.
 *       * Better error messaging/ handling/ Sentry integration for expected errors.
 *       * ...
 */

/**
 * @query keyword: string | undefined
 * @query pegi: string
 * @query _offset: integer
 * @query _limit: integer
 * */
async function getSearchResults(keyword: string | undefined, queryString: ApiQueryString, options: ApiProps) {
  try {
    const { ssr, locale } = options;
    const {
      data,
      // status
    } = await axios.get<GameRequestProps[]>(
      `${ssr ? process.env.PROJECT_API_ORIGINAL_URL : process.env.PROJECT_API_URL}search/find${qs.stringify(
        {
          ...queryString,
          keyword: keyword as string,
        },
        { addQueryPrefix: true }
      )}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Language': locale || '',
          'X-Project-Id': process.env.PROJECT_ID || '',
        },
      }
    );
    // console.log(JSON.stringify(data, null, 4));
    // 👇️ "response status is: 200"
    // console.log('response status is: ', status);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      // 👇️ error: AxiosError<any, any>
    } else {
      console.error(error);
    }
  }
}

/**
 * @query _offset: integer
 * @query _limit: integer
 * @query _asset: string
 * @query _random: boolean
 * @query _gameOffset: integer
 * @query _gameLimit: integer
 * @query _gameAsset: string
 * @query _gameRandom: boolean
 * @query _fieldsGame: string
 *
 * @param type CollectionTypesEnum
 * @param queryString ApiQueryString
 * @param options ApiProps
 */
async function getAllCollections(type: CollectionTypesEnum, queryString: ApiQueryString, options: ApiProps) {
  const { ssr, locale } = options;
  let path;
  if (type === 'category') {
    path = 'categories/list-with-games';
  } else if (type === 'brand') {
    path = 'brands/list-with-games';
  } else if (type === 'collection') {
    path = 'collections/list-with-games';
  }
  try {
    let {
      data,
      // status
    } = await axios.get<CategoryRequestProps[]>(
      `${ssr ? process.env.PROJECT_API_ORIGINAL_URL : process.env.PROJECT_API_URL}${path}${qs.stringify(queryString, {
        addQueryPrefix: true,
      })}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Language': locale || '',
          'X-Project-Id': process.env.PROJECT_ID || '',
        },
      }
    );

    // Include the "type". We can use this to set the path within links and buttons inside our component.
    data = data.map((collection) => ({ ...collection, type }));

    // console.log(JSON.stringify(data, null, 4));
    // 👇️ "response status is: 200"
    // console.log('response status is: ', status);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      // 👇️ error: AxiosError<any, any>
    } else {
      console.error(error);
    }
  }
}

/**
 * Todo: HUZ2ERA-977 - Fetch collection by slug instead of code.
 * @query code: string
 * @query _gameOffset: integer
 * @query _gameLimit: integer
 * @query _gameAsset: string
 * @query _gameRandom: boolean
 * @query _fieldsGame: string
 *
 * @param slug string
 * @param type CollectionTypesEnum
 * @param queryString ApiQueryString
 * @param options ApiProps
 */
async function getCollection(slug: string, type: CollectionTypesEnum, queryString: ApiQueryString, options: ApiProps) {
  const { ssr, locale } = options;
  let path;
  if (type === 'category') {
    path = 'categories/games-by-code';
  } else if (type === 'brand') {
    path = 'brands/games-by-code';
  } else if (type === 'collection') {
    path = 'collections/games-by-code';
  }
  try {
    const {
      data,
      // status
    } = await axios.get<CategoryRequestProps>(
      `${ssr ? process.env.PROJECT_API_ORIGINAL_URL : process.env.PROJECT_API_URL}${path}${qs.stringify(
        {
          ...queryString,
          code: slug as string,
          cache_enabled: false,
        },
        { addQueryPrefix: true }
      )}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Language': locale || '',
          'X-Project-Id': process.env.PROJECT_ID || '',
        },
      }
    );
    // 👇️ "response status is: 200"

    // Include the "type". We can use this to set the path within links and buttons inside our component.
    return { ...data, type };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      // 👇️ error: AxiosError<any, any>
    } else {
      console.error(error);
    }
  }
}

/**
 * @query period: ENUM[day|week|month|quarter|half|year|all] (default: month)
 * @query sameCountry: boolean (default: false)
 * @query sameLanguage: boolean (default: false)
 * @query _offset: integer (default: 0)
 * @query _limit: integer (default: 100)
 * @query _asset: string
 * @query _fieldsGame: string
 *
 * @param queryString ApiQueryString
 * @param options ApiProps
 */
async function getPopularGames(queryString: ApiQueryString, options: ApiProps) {
  try {
    const { ssr, locale } = options;
    const {
      data,
      // status
    } = await axios.get<GameRequestProps[]>(
      `${ssr ? process.env.PROJECT_API_ORIGINAL_URL : process.env.PROJECT_API_URL}games/trending-list${qs.stringify(
        {
          ...queryString,
          cache_enabled: false,
        },
        { addQueryPrefix: true }
      )}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Language': locale || '',
          'X-Project-Id': process.env.PROJECT_ID || '',
        },
      }
    );
    // 👇️ "response status is: 200"
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      // 👇️ error: AxiosError<any, any>
    } else {
      console.error(error);
    }
  }
}

/**
 * @query _order: ENUM[createdAt | updatedAt | publishedAt] (default: updatedAt)
 * @query _offset: integer (default: 0)
 * @query _limit: integer (default: 100)
 * @query _asset: string
 * @query _fieldsGame: string
 *
 * @param queryString ApiQueryString
 * @param options ApiProps
 */
async function getLatestGames(queryString: ApiQueryString, options: ApiProps) {
  try {
    const { ssr, locale } = options;
    const {
      data,
      // status
    } = await axios.get<GameRequestProps[]>(
      `${ssr ? process.env.PROJECT_API_ORIGINAL_URL : process.env.PROJECT_API_URL}games/new-list${qs.stringify(
        { ...queryString, cache_enabled: false },
        {
          addQueryPrefix: true,
        }
      )}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Language': locale || '',
          'X-Project-Id': process.env.PROJECT_ID || '',
        },
      }
    );
    // 👇️ "response status is: 200"
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      // 👇️ error: AxiosError<any, any>
    } else {
      console.error(error);
    }
  }
}

// Todo: HUZ2ERA-977 - Fetch game by slug instead of code.
/**
 * @query code: string
 * @query _fieldsGame: string
 *
 * @param slug string | undefined
 * @param queryString ApiQueryString
 * @param options ApiProps
 */
async function getGame(slug: string | undefined, queryString: ApiQueryString, options: ApiProps) {
  try {
    const { ssr, locale } = options;
    const {
      data,
      // status
    } = await axios.get<GameRequestProps>(
      `${ssr ? process.env.PROJECT_API_ORIGINAL_URL : process.env.PROJECT_API_URL}games/get-by-code${qs.stringify(
        {
          ...queryString,
          code: slug as string,
          cache_enabled: false,
        },
        { addQueryPrefix: true }
      )}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Language': locale || '',
          'X-Project-Id': process.env.PROJECT_ID || '',
        },
      }
    );
    // 👇️ "response status is: 200"
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      // 👇️ error: AxiosError<any, any>
    } else {
      console.error(error);
    }
  }
}

/**
 * @query code: string
 * @response  {id:"", name: "", code:"", asset: {id: ""}}
 *
 * @param slug string | undefined
 * @param queryString ApiQueryString
 * @param options ApiProps
 */
async function getMedias(slug: string | undefined, queryString: ApiQueryString, options: ApiProps) {
  try {
    const { ssr, locale } = options;
    const {
      data,
      // status
    } = await axios.get<MediasRequestProps>(
      `${ssr ? process.env.PROJECT_API_ORIGINAL_URL : process.env.PROJECT_API_URL}medias/get-by-code${qs.stringify(
        {
          ...queryString,
          code: slug as string,
        },
        { addQueryPrefix: true }
      )}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Language': locale || '',
          'X-Project-Id': process.env.PROJECT_ID || '',
        },
      }
    );
    // 👇️ "response status is: 200"
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      // 👇️ error: AxiosError<any, any>
    } else {
      console.error(error);
    }
  }
}

/**
 * @param id string | undefined
 * @param queryString ApiQueryString
 * @param options ApiProps
 */
async function getAsset(id: string | undefined, queryString: ApiQueryString, options: ApiProps) {
  try {
    const { ssr } = options;
    const {
      data,
      // status
    } = await axios.get<string>(
      `${ssr ? process.env.PROJECT_ASSET_ORIGINAL_URL : process.env.PROJECT_ASSET_URL}${id}/${qs.stringify(
        {
          // Media server requires a trailing slash.
          ...queryString,
        },
        { addQueryPrefix: true }
      )}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Project-Id': process.env.PROJECT_ID || '',
        },
      }
    );
    // 👇️ "response status is: 200"
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      // 👇️ error: AxiosError<any, any>
    } else {
      console.error(error);
    }
  }
}

/**
 * Send a message to related receiver
 * @query { source: "huz|customer", subject: "", name: "", email: "", message: "" }
 **/
async function postContactMessage(query: {
  source: string;
  subject: string;
  name: string;
  email: string;
  message: string;
}) {
  try {
    const {
      data,
      // status
    } = await axios.post(`${process.env.PROJECT_API_URL}contacts/send`, query, {
      headers: {
        Accept: 'application/json',
        'X-Project-Id': process.env.PROJECT_ID || '',
      },
    });
    // 👇️ "response status is: 200"
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      // 👇️ error: AxiosError<any, any>
    } else {
      console.error(error);
    }
  }
}

async function getSEO() {
  try {
    const { data } = await axios.get<any[]>(`${process.env.PROJECT_API_ORIGINAL_URL}seos/list`, {
      headers: {
        Accept: 'application/json',
        'X-Language': '',
        'X-Project-Id': process.env.PROJECT_ID || '',
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function profileMe(query: { xSessionId: string }) {
  try {
    const { data } = await axios.get<any[]>(`${process.env.PROJECT_API_ORIGINAL_URL}profiles/me`, {
      headers: {
        Accept: 'application/json',
        'X-Project-Id': process.env.PROJECT_ID || '',
        'X-Session-Id': query.xSessionId || '',
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function myFamily(query: { xSessionId: string }) {
  try {
    const { data } = await axios.get<any[]>(`${process.env.PROJECT_API_ORIGINAL_URL}profiles/family`, {
      headers: {
        Accept: 'application/json',
        'X-Project-Id': process.env.PROJECT_ID || '',
        'X-Session-Id': query.xSessionId || '',
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function addKid(query: { xSessionId: string }, form: IKidForm) {
  try {
    const { data } = await axios.post<any[]>(`${process.env.PROJECT_API_ORIGINAL_URL}profiles/owners/add-kid`, form, {
      headers: {
        Accept: 'application/json',
        'X-Project-Id': process.env.PROJECT_ID || '',
        'X-Session-Id': query.xSessionId || '',
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function editOwner(query: { xSessionId: string }, form: IKidForm) {
  try {
    const { data } = await axios.put<any[]>(`${process.env.PROJECT_API_ORIGINAL_URL}profiles/owners/edit-self`, form, {
      headers: {
        Accept: 'application/json',
        'X-Project-Id': process.env.PROJECT_ID || '',
        'X-Session-Id': query.xSessionId || '',
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function editKid(query: { xSessionId: string }, form: IKidForm) {
  try {
    const { data } = await axios.put<any[]>(`${process.env.PROJECT_API_ORIGINAL_URL}profiles/owners/edit-kid`, form, {
      headers: {
        Accept: 'application/json',
        'X-Project-Id': process.env.PROJECT_ID || '',
        'X-Session-Id': query.xSessionId || '',
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function kidEditSelf(query: { xSessionId: string }, form: IKidForm) {
  try {
    const { data } = await axios.put<IUserProps>(
      `${process.env.PROJECT_API_ORIGINAL_URL}profiles/kids/edit-self`,
      form,
      {
        headers: {
          Accept: 'application/json',
          'X-Project-Id': process.env.PROJECT_ID || '',
          'X-Session-Id': query.xSessionId || '',
        },
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function deleteKid(query: { xSessionId: string }, kidId: string) {
  try {
    const { data } = await axios.delete<any[]>(
      `${process.env.PROJECT_API_ORIGINAL_URL}profiles/owners/delete-kid?id=${kidId}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Project-Id': process.env.PROJECT_ID || '',
          'X-Session-Id': query.xSessionId || '',
        },
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function activateKid(query: { xSessionId: string }, kidId: string) {
  try {
    const { data } = await axios.patch<any[]>(
      `${process.env.PROJECT_API_ORIGINAL_URL}profiles/owners/activate-kid`,
      { id: kidId },
      {
        headers: {
          Accept: 'application/json',
          'X-Project-Id': process.env.PROJECT_ID || '',
          'X-Session-Id': query.xSessionId || '',
        },
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function deactivateKid(query: { xSessionId: string }, kidId: string) {
  try {
    const { data } = await axios.patch<any[]>(
      `${process.env.PROJECT_API_ORIGINAL_URL}profiles/owners/deactivate-kid`,
      { id: kidId },
      {
        headers: {
          Accept: 'application/json',
          'X-Project-Id': process.env.PROJECT_ID || '',
          'X-Session-Id': query.xSessionId || '',
        },
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function validatePin(query: { xSessionId: string }, pin: string) {
  try {
    const { data } = await axios.put<any[]>(
      `${process.env.PROJECT_API_ORIGINAL_URL}profiles/validate-pin`,
      { pin },
      {
        headers: {
          Accept: 'application/json',
          'X-Project-Id': process.env.PROJECT_ID || '',
          'X-Session-Id': query.xSessionId || '',
        },
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function switchUser(query: { xSessionId: string }, pin: string, id: string) {
  try {
    const { data } = await axios.put<any[]>(
      `${process.env.PROJECT_API_ORIGINAL_URL}profiles/switch`,
      { pin, id },
      {
        headers: {
          Accept: 'application/json',
          'X-Project-Id': process.env.PROJECT_ID || '',
          'X-Session-Id': query.xSessionId || '',
        },
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function logout(query: { xSessionId: string }) {
  try {
    const { data } = await axios.get<any[]>(`${process.env.PROJECT_SUBSCRIPTION_API_URL}subscription/logout`, {
      headers: {
        Accept: 'application/json',
        'X-Project-Id': process.env.PROJECT_ID || '',
        'X-Session-Id': query.xSessionId || '',
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

async function getPegiList() {
  try {
    const { data } = await axios.get<Pegi[]>(`${process.env.PROJECT_API_ORIGINAL_URL}pegis/list`, {
      headers: {
        Accept: 'application/json',
        'X-Project-Id': process.env.PROJECT_ID || '',
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
    } else {
      console.error(error);
    }
  }
}

export {
  postContactMessage,
  getAllCollections,
  getSearchResults,
  getPopularGames,
  getLatestGames,
  getCollection,
  getSEO,
  getMedias,
  getAsset,
  getGame,
  // Authentication Requests
  profileMe,
  myFamily,
  addKid,
  editOwner,
  kidEditSelf,
  editKid,
  deleteKid,
  activateKid,
  deactivateKid,
  validatePin,
  switchUser,
  logout,
  // Pegi
  getPegiList,
};
