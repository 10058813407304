import React from 'react';
import styled from '@emotion/styled';
import Image, { ImageLoaderProps } from 'next/image';
import { useTheme } from '@mui/styles';
import { useAppContext } from '../../contexts/AppContext';
import Button from '@mui/material/Button';
import { Link as LinkComponent } from '../Link';
import { useTranslation } from 'next-i18next';
import { useEditorHandlers } from './@editor';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';

// icons"
import FacebookIcon from './icons/facebook';
import YoutubeIcon from './icons/youtube';
import TwitterIcon from './icons/twitter';
import InstagramIcon from './icons/instagram';
import TiktokIcon from './icons/tiktok';
import TwitchIcon from './icons/twitch';
import LinkedInIcon from './icons/linkedin';
import SbtGamesIcon from './icons/sbtgames';
import { SocialMediaLinksProps } from '../../types';

const LinkWrapper = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(LinkComponent, { forwardedRef: ref, ...props })
);

const Link = React.forwardRef<HTMLAnchorElement, any>((props, ref) =>
  useHighlightHandlers(LinkComponent, { forwardedRef: ref, ...props })
);

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  top: '100%',
}));

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  backgroundColor: theme.theme_component_footer_bar_background_color || theme.palette.primary.main,
  backgroundImage: theme.theme_component_footer_bar_background_image
    ? `url(https://${theme.theme_component_footer_bar_background_image}/)`
    : 'none',
  backgroundPosition: theme.theme_component_footer_bar_background_position || 'center',
  backgroundSize: theme.theme_component_footer_bar_background_size || 'cover',
  backgroundRepeat: theme.theme_component_footer_bar_background_repeat || 'no-repeat',
  color: theme.theme_component_footer_bar_color || theme.palette.primary.contrastText,
  justifyContent: 'space-between',
  outlineOffset: '-2px', // Help out cursor context editor.
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'none',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    alignItems: 'left',
  },
}));

const LinkWrap = styled(LinkWrapper)(({ theme }) => ({
  color: theme.theme_component_footer_bar_link_color || theme.palette.primary.contrastText,
  fontSize: theme.theme_component_footer_bar_link_font_size || '14px',
  fontWeight: theme.theme_component_footer_bar_link_font_weight || 400,
  textTransform: 'capitalize',
  textDecoration: 'none',
  margin: 'auto 0',
}));

const ColophonTextWrapper = styled('div')(({ theme }) => ({
  margin: 'auto 0',
}));

const LogoImage = styled(Image)(({ theme }) => ({
  height: '100% !important',
  minHeight: ' 100% !important',
  width: 'auto !important',
  minWidth: 'auto !important',
  margin: '0 !important',
}));

const GameLogoWrapper = styled('div')(({ theme }) => ({
  height: 120,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    height: 90,
  },
}));

const SocialMediaWrapper = styled('div')(({ theme }) => ({
  marginRight: 30,
  marginLeft: 30,
  height: 120,
  [theme.breakpoints.up('sm')]: {
    height: 90,
  },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(1),
    justifyContent: 'flex-start',
    marginRight: 30,
  },
  display: 'flex',
  flexWrap: 'nowrap',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const PrivacyParent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flex: '1',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const PrivacyChildren = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'left',
    marginRight: 30,
    marginLeft: 30,
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: ' flex-start',
    marginLeft: 0,
    div: {
      padding: '8px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '1 0 0 1',
  },
}));

const IconsWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing('auto', 0, 'auto', 0),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  height: '100%',
}));

const PolicyWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(4),
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(4),
  },
}));

const LogoImageContainer = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2.1),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  minWidth: '150px',
  maxHeight: '60px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: theme.spacing(0),
    img: {},
  },
})) as typeof Button;

const AzerionBrandContainer = styled('div')(() => ({
  backgroundColor: 'black',
  color: 'white',
}));

const AzerionBrandContent = styled('div')(() => ({
  backgroundColor: 'black',
  color: 'white',
  marginLeft: 30,
  padding: '10px 0',
}));

const MyAuto = styled('div')(() => ({}));

interface SocialMediaIconProps {
  platform: string;
  theme?: any;
  className?: string;
  key?: any;
  href?: string;
}

const BaseIcon = ({ className, platform, href }: SocialMediaIconProps) => {
  const theme = useTheme() as any;
  const IconComponent = {
    linkedin: LinkedInIcon,
    twitch: TwitchIcon,
    youtube: YoutubeIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
    sbt: SbtGamesIcon,
    facebook: FacebookIcon,
    tiktok: TiktokIcon,
  }[platform.toLowerCase()];

  return IconComponent ? (
    <Link href={href} target="_blank" rel="noreferrer">
      <IconComponent
        className={className}
        style={{}}
        color={theme.theme_component_navigation_bar_background_color || theme.palette.primary.main}
      />
    </Link>
  ) : null;
};

// Styled version of the component
const SocialMediaIcon = styled(BaseIcon)(({ theme }) => ({
  marginRight: '15px',
}));

export const FooterBar = () => {
  const theme = useTheme();
  const { t } = useTranslation(['footerbar']);
  const appContext = useAppContext();
  const { url, assetUrl, footerLogoId, componentOptions } = appContext.useConfig();
  //const cookie = Package.imports.project?.features?.cookie;

  const privacyUrlDisabled = componentOptions.component_footer_bar_privacy_url_disabled;
  const termsUrlDisabled = componentOptions.component_footer_bar_terms_url_disabled;
  const cookiesUrlDisabled = componentOptions.component_footer_bar_cookies_url_disabled;
  const cookieManagementEnabled = componentOptions.component_footer_bar_cookie_management_enabled;
  const helpCenterUrlDisabled = componentOptions.component_footer_bar_help_center_url_disabled;
  const contactUrlDisabled = componentOptions.component_footer_bar_contact_url_disabled;
  const privacyUrl = componentOptions.component_footer_bar_privacy_url;
  const termsUrl = componentOptions.component_footer_bar_terms_url;
  const cookiesUrl = componentOptions.component_footer_bar_cookies_url;
  const helpCenterUrl = componentOptions.component_footer_bar_help_center_url;
  const contactUrl = componentOptions.component_footer_bar_contact_url;
  const colophonText = componentOptions.component_footer_bar_colophon_text;
  const logoUrl = componentOptions.component_footer_bar_logo_url;
  const azerionBrand = componentOptions?.component_footer_bar_azerion_footer;

  const socialMediaLinks = componentOptions?.component_social_media_links || [];

  const handleCookieClick = () => {
    if (window.OneTrust?.ToggleInfoDisplay) {
      window.OneTrust?.ToggleInfoDisplay();
    }
  };
  const body1Ref = React.useRef<HTMLInputElement | null>(null);
  const body1Ref2 = React.useRef<HTMLInputElement | null>(null);
  const body1Ref3 = React.useRef<HTMLInputElement | null>(null);
  const body1Ref5 = React.useRef<HTMLInputElement | null>(null);
  const body1Ref6 = React.useRef<HTMLInputElement | null>(null);

  function generateRandomId() {
    return Math.random().toString(36).substr(2, 9); // 36 tabanlı sayı, harf ve rakam kombinasyonu üretir
  }

  return (
    <Container>
      <Root {...useEditorHandlers('Footer Bar')}>
        <GameLogoWrapper>
          <IconsWrapper>
            {footerLogoId &&
              (logoUrl ? (
                <Button component={Link} className="p-0" href={logoUrl}>
                  <LogoImage
                    height="46"
                    width={'100%'}
                    unoptimized={true}
                    src={`${assetUrl}${footerLogoId}/`}
                    alt={'logo-url'}
                  />
                </Button>
              ) : (
                <LogoImageContainer component={Link} href="/" className="p-0">
                  <LogoImage
                    loader={({ src }: ImageLoaderProps) => src}
                    layout="fill"
                    objectFit="contain"
                    unoptimized={true}
                    src={`${assetUrl}${footerLogoId}/`}
                    alt={'logo-inner-url'}
                    width={100}
                  />
                </LogoImageContainer>
              ))}
          </IconsWrapper>
        </GameLogoWrapper>
        <PrivacyParent>
          <PrivacyChildren>
            {!privacyUrlDisabled && (
              <PolicyWrap>
                {privacyUrl ? (
                  <LinkWrap
                    ref={body1Ref}
                    href={privacyUrl}
                    target="_blank"
                    rel="noreferrer"
                    variant="body1"
                    underline="hover"
                    title={t('privacy_statement', 'Privacy statement')}
                  >
                    {t('privacy_statement', 'Privacy statement')}
                  </LinkWrap>
                ) : (
                  <LinkWrap
                    ref={body1Ref}
                    href="/legal/privacy-statement"
                    variant="body1"
                    underline="hover"
                    title={t('privacy_statement', 'Privacy statement')}
                  >
                    {t('privacy_statement', 'Privacy statement')}
                  </LinkWrap>
                )}
              </PolicyWrap>
            )}
            {!termsUrlDisabled && (
              <PolicyWrap>
                {termsUrl ? (
                  <LinkWrap
                    ref={body1Ref2}
                    href={termsUrl}
                    target="_blank"
                    rel="noreferrer"
                    variant="body1"
                    underline="hover"
                    title={t('terms_of_use', 'Terms of use')}
                  >
                    {t('terms_of_use', 'Terms of use')}
                  </LinkWrap>
                ) : (
                  <LinkWrap
                    ref={body1Ref2}
                    href="/legal/terms-of-use"
                    variant="body1"
                    underline="hover"
                    title={t('terms_of_use', 'Terms of use')}
                  >
                    {t('terms_of_use', 'Terms of use')}
                  </LinkWrap>
                )}
              </PolicyWrap>
            )}
            {
              <>
                {!cookiesUrlDisabled && (
                  <PolicyWrap>
                    {cookiesUrl ? (
                      <LinkWrap
                        ref={body1Ref3}
                        href={cookiesUrl}
                        target="_blank"
                        rel="noreferrer"
                        variant="body1"
                        underline="hover"
                        title={t('footer.cookie_policy', 'Cookie Policy')}
                      >
                        {t('footer.cookie_policy', 'Cookie Policy')}
                      </LinkWrap>
                    ) : (
                      <LinkWrap
                        ref={body1Ref3}
                        href="/legal/cookie-statement"
                        variant="body1"
                        underline="hover"
                        title={t('footer.cookie_policy', 'Cookie Policy')}
                      >
                        {t('footer.cookie_policy', 'Cookie Policy')}
                      </LinkWrap>
                    )}
                  </PolicyWrap>
                )}
                {cookieManagementEnabled && (
                  <PolicyWrap>
                    <LinkWrap
                      id="ot-sdk-btn"
                      href={'/'}
                      onClick={handleCookieClick}
                      underline="hover"
                      variant="body1"
                      title={t('cookie_management', 'Cookie management')}
                    >
                      {t('cookie_management', 'Cookie management')}
                    </LinkWrap>
                  </PolicyWrap>
                )}
              </>
            }
            {!helpCenterUrlDisabled && (
              <PolicyWrap>
                {helpCenterUrl ? (
                  <LinkWrap
                    href={helpCenterUrl}
                    target="_blank"
                    rel="noreferrer"
                    variant="body1"
                    underline="hover"
                    title={t('footer.help_center', 'Help Center')}
                    ref={body1Ref5}
                  >
                    {t('footer.help_center', 'Help Center')}
                  </LinkWrap>
                ) : (
                  <LinkWrapper passHref href={'/help-center'} as={'/help-center'}>
                    <LinkWrap
                      ref={body1Ref5}
                      href={'/help-center'}
                      variant="body1"
                      underline="hover"
                      title={t('footer.help_center', 'Help Center')}
                    >
                      {t('footer.help_center', 'Help Center')}
                    </LinkWrap>
                  </LinkWrapper>
                )}
              </PolicyWrap>
            )}
            {!contactUrlDisabled && (
              <PolicyWrap>
                {contactUrl ? (
                  <LinkWrap
                    href={contactUrl}
                    target="_blank"
                    rel="noreferrer"
                    variant="body1"
                    underline="hover"
                    title={t('footer.contact', 'Contact')}
                    ref={body1Ref6}
                  >
                    {t('footer.contact', 'Contact')}
                  </LinkWrap>
                ) : (
                  <LinkWrap
                    ref={body1Ref6}
                    href={'/contact'}
                    variant="body1"
                    underline="hover"
                    title={t('footer.contact', 'Contact')}
                  >
                    {t('footer.contact', 'Contact')}
                  </LinkWrap>
                )}
              </PolicyWrap>
            )}
            {colophonText && (
              <PolicyWrap>
                <ColophonTextWrapper>{t('colophon_text', colophonText)}</ColophonTextWrapper>
              </PolicyWrap>
            )}
          </PrivacyChildren>
          <SocialMediaWrapper>
            <MyAuto className="my-auto">
              {socialMediaLinks?.map((link: SocialMediaLinksProps) => (
                <SocialMediaIcon className="" key={link.platform} platform={link.platform} href={link.href} />
              ))}
            </MyAuto>
          </SocialMediaWrapper>
        </PrivacyParent>
      </Root>
      {azerionBrand && (
        <AzerionBrandContainer>
          <AzerionBrandContent>
            <Image
              src="/images/azerion-brand.png"
              loader={({ src, width }: ImageLoaderProps) => `${src}?w=${width || 180}`}
              alt="made-with-love"
              height="30"
              width="180"
            />
          </AzerionBrandContent>
        </AzerionBrandContainer>
      )}
    </Container>
  );
};
