import React from 'react';
import { CollectionSection } from '../../components/CollectionSection';
import type { CategoriesViewProps } from '../../types';

export const CollectionHoc = (
  WrappedComponent: React.ComponentType<CategoriesViewProps>
): React.FC<CategoriesViewProps> => {
  const WithHoc: React.FC<CategoriesViewProps> = (props: any) => {
    return (
      <CollectionSection>
        <WrappedComponent {...props} />
      </CollectionSection>
    );
  };

  return WithHoc;
};
