import * as React from 'react';
import styled from '@emotion/styled';
import { AdvertisementSlot } from '../../components/AdvertisementSlot';
import { useAppContext } from '../../contexts/AppContext';
import { AGGRESSIVE_HOME_TOP_ADS_CONFIGURATION } from '../../types/static';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { isMobile } from 'react-device-detect';

const SectionTop = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(7),
  width: '100%',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flex: 1,
  // '@media screen and (max-width: 1220px)': {
  //   maxWidth: 320,
  // },
}));

const AdvertisementTopSlot = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  backgroundColor: 'grey',
}));

const AdvertisementTop = ({ slotIdentifier, lg }: { slotIdentifier: string; lg: boolean }) => {
  return (
    <AdvertisementTopSlot
      configuration={AGGRESSIVE_HOME_TOP_ADS_CONFIGURATION}
      areaName="aggressiveHomeTopAds"
      elevation={1}
      slotIdentifier={slotIdentifier}
      variant={!lg ? '320x100' : '728x90'}
    />
  );
};

export const AggressiveHomeTopAds = () => {
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <SectionTop maxWidth={false}>
      {lg && !componentOptions.component_top_ads_disabled && (
        <AdvertisementTop slotIdentifier={componentOptions?.aggressive_home_atf_id || ''} lg={lg} />
      )}
      {!lg && !componentOptions.component_top_ads_mobile_disabled && (
        <AdvertisementTop slotIdentifier={componentOptions?.aggressive_home_atf_id || ''} lg={lg} />
      )}
    </SectionTop>
  );
};
