import React, { useState } from 'react';
import { Card } from '../Card';
import { useAppContext } from '../../contexts/AppContext';
import styled from '@emotion/styled';
import { Box, Button, useTheme } from '@mui/material';
import { FEATURE_GRID_A_CARD_BUTTON_STYLE } from '../../types/static';
import { setDefaultStyleValues } from '../../utils/set-default-style-values';
import { Card as MuiCard } from '@mui/material';
import { CustomButton } from '../CustomButton';
import { Link } from '../Link';
import { Title } from '../Title';
import { isValidUrl } from '../../utils/is-valid-url';
import { useTranslation } from 'next-i18next';

export interface CollectionCommonCProps {
  template: string;
  items: any;
  component?: any;
  type?: any;
  slug?: any;
  name?: any;
}

const createGridTemplateAreas = (itemCount: number) => {
  let areas = `
    "main main side"
    "main main bottom"
  `;

  const extraRows = Math.ceil((itemCount - 3) / 3);
  for (let i = 0; i < extraRows; i++) {
    areas += `"extra${i * 3 + 1} extra${i * 3 + 2} extra${i * 3 + 3}"\n`;
  }

  return areas;
};

const ProductSection = styled('div')<{ itemCount: number }>(({ theme, itemCount }) => ({
  display: 'grid',
  gap: theme.spacing(3),
  padding: 0,
  gridTemplateAreas: createGridTemplateAreas(itemCount),
  gridTemplateColumns: 'repeat(3, 1fr)',

  [theme.breakpoints.down('md')]: {
    gridTemplateAreas: 'none',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),
    '& > *:first-of-type': {
      gridColumn: '1 / -1',
      aspectRatio: '1/1',
      height: 'auto',
      '& img': {
        height: '100%',
        objectFit: 'cover',
      },
    },
    '& > *:not(:first-of-type)': {
      '& img': {
        height: '100%',
        objectFit: 'cover',
      },
    },
  },
}));

const CardStyled = styled(Card)(({ theme, template, hoverEffect = true, cardBorder }: any) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: theme.theme_component_card_background_color || theme.palette.background.paper,
  boxShadow: theme.theme_component_card_box_shadow || theme.shadows[3],
  borderRadius: template === 'big' ? 0 : `${theme.theme_component_card_border_radius}px` || theme.shape.borderRadius,
  border: cardBorder || `${theme.theme_component_card_content_border}px` || 'none',
  borderStyle: theme.theme_component_card_content_border ? 'solid' : 'none',
  borderColor: theme.theme_component_card_content_border ? theme.theme_component_card_content_border_color : 'none',
  transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,

  '&:hover': hoverEffect && {
    transform: 'scale3d(1.02, 1.02, 1)',
  },

  '& .inner-card': {
    height: '100%',
    '& > div:first-of-type': {
      overflow: 'hidden',
      height: '100%',
    },
  },

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const MainCard = styled(CardStyled)(({ theme }) => ({
  gridArea: 'main',
  aspectRatio: '16/9',
  [theme.breakpoints.down('md')]: {
    gridArea: 'unset',
    width: '100%',
    height: 650,
    '& img': {
      height: '100%',
      objectFit: 'cover',
    },
  },
}));

const SideCard = styled(CardStyled)(({ theme }) => ({
  gridArea: 'side',
  aspectRatio: '4/4',
  [theme.breakpoints.down('md')]: {
    gridArea: 'unset',
    '& img': {
      height: '100%',
      objectFit: 'cover',
    },
  },
}));

const BottomCard = styled(CardStyled)(({ theme }) => ({
  gridArea: 'bottom',
  aspectRatio: '4/4',
  [theme.breakpoints.down('md')]: {
    gridArea: 'unset',
  },
}));

const ExtraCard = styled(CardStyled)(({ theme }) => ({
  aspectRatio: '4/4',
  [theme.breakpoints.down('md')]: {
    '& img': {
      height: '100%',
      objectFit: 'cover',
    },
  },
}));

const MoreButton = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  marginTop: theme.spacing(3),
}));

export const FeatureGridA = (props: CollectionCommonCProps) => {
  const theme = useTheme();
  const { items = [], template, component, type, slug, name } = props;
  const [showAll, setShowAll] = useState(false);
  const appContext = useAppContext();
  const { assetUrl } = appContext.useConfig();
  const validUrl = isValidUrl(assetUrl?.replace(/\/+$/, ''));
  const { t } = useTranslation('card');

  const visibleItems = showAll ? items : items.slice(0, 3);
  const baseImageUrl = validUrl ? '' : assetUrl;

  const getImageUrl = (game: any, index: number) => {
    return `${baseImageUrl}${
      [1, 4, 7].includes(index)
        ? game.images['512x512'] || game.images['512x384'] || game.images.cover
        : game.images['512x384'] || game.images['512x512'] || game.images.cover
    }/`;
  };

  const renderCard = (game: any, index: number) => {
    const commonProps = {
      key: `${game.id}_${index}`,
      href: `/game/${game.slug}`,
      src: getImageUrl(game, index),
      alt: game.name,
      collection: { name, slug },
      template: template,
      layout: 'responsive',
      hideDescription: component?.hideDescription,
      configurationStyle: setDefaultStyleValues(FEATURE_GRID_A_CARD_BUTTON_STYLE, theme),
    };

    if (index === 0) {
      return (
        <MainCard {...commonProps} width={700} height={550} description={game.description || ''} title={game.name} />
      );
    } else if (index === 1) {
      return <SideCard {...commonProps} width={310} height={265} />;
    } else if (index === 2) {
      return <BottomCard {...commonProps} width={310} height={265} />;
    }

    return <ExtraCard {...commonProps} style={{ gridArea: `extra${index - 2}` }} width={310} height={265} />;
  };

  return (
    <Box sx={{ mb: 5 }}>
      {!component?.hideTitle && (
        <Link href={`/${type}/${slug}`} title={name} underline="none" sx={{ display: 'block', mb: 3 }}>
          <Title variant="h2">{name}</Title>
        </Link>
      )}

      <ProductSection itemCount={visibleItems.length}>
        {visibleItems.map((game: any, index: number) => renderCard(game, index))}
      </ProductSection>

      {!showAll && items.length > 3 && (
        <MoreButton>
          <CustomButton 
            color="primary" 
            variant="contained" 
            onClick={() => setShowAll(true)}
          >
            {t('more_games', 'More Games')}
          </CustomButton>
        </MoreButton>
      )}
    </Box>
  );
};
